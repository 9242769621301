<template>
<div class="bg-round d-flex justify-content-center align-items-center">
  <div class="container error-area">
    <article class="d-flex flex-column">
      <div class="error-img"></div>
      <div class="error-box">
        <h2>404</h2>
        <div>
          <p>ERROR!</p>
          <span>Page Not Found</span>
        </div>
      </div>
    </article>
  </div>
</div>
</template>

<script>
export default {
  beforeCreate () {
    document.body.className = 'page-404'
  },
}
</script>
